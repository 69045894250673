<script>
  export let inversed = false,
    class_name = "";
</script>

{#if inversed}
  <button
    on:click
    class="bg-[#f5f5f6] hover:bg-accent max-sm:flex max-sm:flex-wrap
        text-primary hover:text-[#f5f5f6] font-semibold py-2 px-4 border
        border-accent hover:border-transparent rounded-md {class_name}"
  >
    <slot></slot>
  </button>
{:else}
  <button
    on:click
    class="hover:bg-[#f5f5f6] bg-accent max-sm:flex max-sm:flex-nowrap
        font-semibold text-[#f5f5f6] hover:text-primary py-2 px-4 border
        hover:border-accent border-transparent rounded-md {class_name}"
  >
    <slot></slot>
  </button>
{/if}
